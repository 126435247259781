<div class="padding">
    <form [formGroup]="assetDetails">

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Asset Type</mat-label>
                    <mat-select [formControlName]="'assetTypeID'" placeholder="Asset Type">
                        <mat-option *ngFor="let option of assetTypes" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['assetTypeID']"
                    [message]="assetTypeIdError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container" *ngIf="this.assetDetails.get('assetTypeID')?.value === manufacturedHomeAssetType">
            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Mobile Home Registration Number</mat-label>
                    <input matInput [formControlName]="'mobileHomeRegistrationNumber'" name="mobile-home-registration-number" placeholder="Mobile Home Registration Number">
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['mobileHomeRegistrationNumber']"
                    [message]="mobileHomeRegistrationNumberError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field appearance="outline" class="form-field flex-item">
                    <mat-label>Serial Number</mat-label>
                    <input matInput (keyup.enter)="decodeVin()" [formControlName]="'serialNumber'" name="serial-number" placeholder="Serial Number">
                    <button
                    matSuffix mat-raised-button
                        (click)="decodeVin()"
                        color="accent"
                        class="padding-sides-15"
                        aria-label="Decode Serial Number button">
                        <mat-spinner *ngIf="isDecodingSerialNumber" [diameter]="20"></mat-spinner>
                        <!-- Show button text only when not loading -->
                        <span *ngIf="!isDecodingSerialNumber">Decode</span>
                    </button>
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['serialNumber']"
                    [message]="serialNumberError$"></lib-error-bubble>
            </div>
            <mat-icon color="primary" class="info-icon padding-top-20" matTooltip="The VIN decoder uses information obtained from a third party. Please review the data for accuracy prior to submission.">info_outline</mat-icon>
        </div>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Year</mat-label>
                    <input matInput [formControlName]="'year'" name="year" placeholder="Year" type="number">
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['year']"
                    [message]="yearError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Make</mat-label>
                    <input matInput [formControlName]="'make'" name="make" placeholder="Make">
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['make']"
                    [message]="makeError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Model</mat-label>
                    <input matInput [formControlName]="'model'" name="model" placeholder="Model">
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['model']"
                    [message]="modelError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container" *ngIf="uiConfiguration.showColor">
            <div class="validated-form-field-container flex-container">
                <mat-form-field class="flex-item form-field" appearance="outline">
                    <mat-label>Colour</mat-label>
                    <input matInput [formControlName]="'color'" name="color" placeholder="Colour">
                </mat-form-field>
                <lib-error-bubble [control]="assetDetails.controls['color']"
                    [message]="colorError$"></lib-error-bubble>
            </div>
        </div>

        <div class="flex-container">
            <mat-list *ngIf="cleanedErrorList.length > 0">
                <mat-list-item *ngFor="let error of cleanedErrorList">
                    <mat-icon matListItemIcon>warning</mat-icon>
                    <div matListItemTitle>{{ error }}</div>
                  </mat-list-item>
            </mat-list>
        </div>

        <!-- Navigation buttons -->
        <div class="flex-container padding-top">
            <button
                mat-raised-button
                (click)="clearFields()"
                class="flex-item form-field previous-button"
                aria-label="Previous step button"
                >
                Clear Fields
            </button>
            <button
                mat-raised-button
                (click)="removeAsset()"
                class="padding-sides-15 flex-item form-field previous-button"
                aria-label="Delete asset button"
                color="warn"
                >
                Delete Asset
            </button>
            
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>
            <span class="spacer"></span>

            <button
                mat-raised-button
                (click)="saveAsset()"
                color="primary"
                class="padding-sides flex-item form-field"
                aria-label="Next step button"
                >
                Save Asset
            </button>
            <!-- [class.spinner]="this.isSaving"
                [disabled]="this.isSaving" -->
        </div>
    </form>
</div>