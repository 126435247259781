import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrderStateChange, Order, OrderManagerService, OrderRepositoryService, OrderStateRepositoryService, eOrderType } from 'reg-hub-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
  @Input() order!: Order;
  protected baseOrderDetails!: FormGroup;
  protected isSaving: boolean = false;

  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private ordersRepo: OrderRepositoryService,
    private orderManager: OrderManagerService,
    private orderStateRepo: OrderStateRepositoryService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.baseOrderDetails = this.formBuilder.group({
      referenceNumber: [this.order.referenceNumber, Validators.required],
      country: [this.order.country, Validators.required],
      jurisdiction: [this.order.jurisdiction, Validators.required],
      orderTypeID: [this.order.orderTypeID, Validators.required],
      originalOrderID: [this.order.id, Validators.required]
    })
  }

  amend() {
    // replacer excludes properties with ID
    var copiedOrder = JSON.parse(JSON.stringify(this.order));
    copiedOrder.originalOrderID = this.order.id;
    copiedOrder.orderTypeID = eOrderType.amendment;

    delete copiedOrder.id // Remove ID from request

    this.requestOrder(eOrderType.amendment, copiedOrder);
  }

  renew() {
    this.requestOrder(eOrderType.renewal)
  }

  discharge() {
    this.requestOrder(eOrderType.discharge);
  }

  edit() {
    const newStatus = {
      newStatus: "HistoricalDraft",
      status: "",
      message: "Cancelled from Reg Hub Client"
    }

    this.orderStateRepo.updateOrderState(this.order.id, newStatus)
      .subscribe(result => {
        this.isSaving = false;
        this.router.navigate([
          `new-registration/${this.order.id}`
        ]);
      })
  }

  requestOrder(orderTypeID: eOrderType, order?: Order): boolean {
    this.baseOrderDetails.patchValue({
      orderTypeID: orderTypeID
    });
    
    this.isSaving = true;

    // Otherwise, create it
    this.ordersRepo.createOrder("orders", order ?? this.baseOrderDetails.value)
    .subscribe(result => {
      this.isSaving = false;
      const order = (result as any).resource;
      this.orderManager.updateOrder(order);
      if (this.order.id) {
        this.router.navigate([
          `new-registration/${order.id}`
        ]);
      }
    })

    // do validation here
    return true;
  }
}
