<app-validation-problems [saving$]="saving$"></app-validation-problems>

<div *ngIf="uiConfiguration.amendmentConfiguration?.showChangeText">
    <h3>Change Text</h3>
    <lib-change-text></lib-change-text>
</div>

<div *ngIf="uiConfiguration.hasReferenceRegistrationInformation">
    <h3>Existing Registration Details</h3>
    <lib-existing-registration-details
    [uiConfiguration]="uiConfiguration.referenceRegistrationInformationUIConfiguration!">
    </lib-existing-registration-details>
</div>

<h3>{{order.orderType.name}} Details</h3>
<div class="flex-item-no-padding">
    <lib-lien-details [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"></lib-lien-details>
</div>
<lib-lien-results [uiConfiguration]="uiConfiguration.lienDetailsConfiguration!"
    [results]="order.lien?.registrationResults?.at(0)" *ngIf="isDischarge() || isHistorical()"
    class="flex-item"></lib-lien-results>

<div *ngIf="uiConfiguration?.hasSecuredParties">
    <h3>Secured Parties</h3>
    <lib-parties-list  
        [order]="order"
        [observableParties$]="securedParties"
        [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.securedPartiesConfiguration!">
    </lib-parties-list>
</div>

<div *ngIf="uiConfiguration?.hasDebtors">
    <h3>Debtors</h3>
    <lib-parties-list 
        partyTypeTitle="Debtor"
        [order]="order"
        [observableParties$]="debtors"
        [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.debtorsConfiguration!">
    </lib-parties-list>
</div>

<div *ngIf="uiConfiguration?.hasDealers">
    <h3>Dealers</h3>
    <lib-parties-list
        partyTypeTitle="Dealer"
        [order]="order"
        [observableParties$]="dealers"
        [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.dealersConfiguration!">
    </lib-parties-list>
</div>

<div *ngIf="uiConfiguration?.hasRegisteringAgents">
    <h3>Registering Agents</h3>
    <lib-parties-list
        partyTypeTitle="Registering Agent"
        [order]="order"
        [observableParties$]="registeringAgents"
        [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.registeringAgentsConfiguration!">
    </lib-parties-list>
</div>

<lib-general-collateral 
    *ngIf="uiConfiguration?.collateralConfiguration?.hasGeneralCollateral"
    [uiConfiguration]="uiConfiguration.collateralConfiguration?.generalCollateralConfiguration"
    [hideHistory]="true"></lib-general-collateral>

<div *ngIf="uiConfiguration?.collateralConfiguration?.hasAssets">
    <h3>Assets</h3>
    <lib-asset-list
        [order]="order"
        [isEditable]="false"
        [uiConfiguration]="uiConfiguration!.collateralConfiguration!.assetConfiguration!">
    </lib-asset-list>
</div>