import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Order, OrderManagerService, PathConversionService, Validation, ValidationProblem, ValidationService } from 'reg-hub-common';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-validation-problems',
  templateUrl: './validation-problems.component.html',
  styleUrls: ['./validation-problems.component.css'],
  animations: [
    trigger('slideDown', [
      state('hidden', style({
        height: '0px',
        opacity: 0
      })),
      state('visible', style({
        height: '*',
        opacity: 1
      })),
      transition('hidden <=> visible', [
        animate('0.5s cubic-bezier(0.5, 0.1, 0.1, 1)')
      ])
    ])
  ]
})
export class ValidationProblemsComponent implements OnInit, OnDestroy {
  @Input() saving$!: Observable<boolean>;

  protected validation: Validation | null | undefined;
  private order!: Order;
  protected showWarnings = false;
  protected showErrors = false;

  private savingSubscription!: Subscription;

  constructor(
    private orderManagerService: OrderManagerService,
    private pathConverstionService: PathConversionService,
    private validationService: ValidationService) { }

  ngOnInit(): void {
    this.order = this.orderManagerService.currentOrder.value;

    this.saving$
      .subscribe(value => {
        if (value === false) {
          this.validation = this.validationService.currentValidation$.value;
          this.order = this.orderManagerService.currentOrder.value;

          if (this.hasWarnings()) {
            this.showWarnings = true;
          }

          if (this.hasErrors()) {
            this.showErrors = true;
          }
          
          this.savingSubscription?.unsubscribe();
        }
      });
  }

  ngOnDestroy(): void {
    this.savingSubscription?.unsubscribe();
  }

  getMessage(problem: ValidationProblem): string {
    const convertedPath = this.pathConverstionService.convertPath(this.order, problem.path);

    if (convertedPath) {
      return `${convertedPath}: ${problem.userFriendlyMessage}`;
    } else {
      return problem.userFriendlyMessage ?? '';
    }
  }

  hasWarnings() {
    return (this.validation?.warnings?.length ?? 0) > 0;
  }

  hasErrors() {
    return (this.validation?.errors?.length ?? 0) > 0;
  }
}
