import { Component } from '@angular/core';
import { ABLienOrderForUpdate, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-ab-lien-details',
  templateUrl: './ab-lien-details.component.html',
  styleUrls: ['./ab-lien-details.component.css']
})
export class ABLienDetailsComponent extends BaseLienDetailsComponent {
  protected courtOrderError$ = new BehaviorSubject<string | null | undefined>(null);
  protected otherChangesError$ = new BehaviorSubject<string | null | undefined>(null);
  protected vehicleReleasedDateError$ = new BehaviorSubject<string | null | undefined>(null);
  protected workLastCompletedDateError$ = new BehaviorSubject<string | null | undefined>(null);
  protected partsProvidedDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  protected override buildForm(): FormGroup {
    const form = this.formBuilder.group({ }) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showTerm) {
      form.addControl('term', new FormControl(this.order.lien?.term));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showInfinityTerm) {
      form.addControl('isInfiniteTerm', new FormControl(this.order.lien?.isInfiniteTerm ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', new FormControl(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showTrustIndenture) {
      form.addControl('trustIndenture', new FormControl(this.order.lien?.trustIndenture ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', new FormControl(this.order.lien?.additionalInformation));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showCourtOrder) {
      form.addControl('courtOrder', new FormControl(this.order.lien?.courtOrder));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showOtherChanges) {
      form.addControl('otherChanges', new FormControl(this.order.lien?.otherChanges));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showStillHavePossession) {
      form.addControl('stillHavePossession', new FormControl(this.order.lien?.stillHavePossession ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showVehicleReleasedDate) {
      var vehicleReleasedDateControl = new FormControl(this.order.lien?.vehicleReleasedDate);

      if(this.order.lien?.stillHavePossession) {
        vehicleReleasedDateControl.disable();
      }

      form.addControl('vehicleReleasedDate', vehicleReleasedDateControl);
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showWorkLastCompletedDate) {
      form.addControl('workLastCompletedDate', new FormControl(this.order.lien?.workLastCompletedDate));
    }
    
    if(this.uiConfiguration.lienDetailsConfiguration?.showProvidedPartsOrAccessories) {
      form.addControl('providedPartsOrAccessories', this.formBuilder.control(this.order.lien?.providedPartsOrAccessories ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showPartsProvidedDate) {
      const partsProvidedControl = this.formBuilder.control(this.order.lien?.partsProvidedDate);

      if(this.order.lien?.providedPartsOrAccessories ?? false === false) {
        partsProvidedControl.disable();
      }

      form.addControl('partsProvidedDate', partsProvidedControl);
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showAdditionalInformation) {
      form.addControl('additionalInformation', new FormControl(this.order.lien?.additionalInformation));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showStillHavePossession &&
      this.uiConfiguration.lienDetailsConfiguration?.showVehicleReleasedDate) {
        const stillHavePossessionControl = form.get('stillHavePossession');

        stillHavePossessionControl?.valueChanges
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(value => {
            const vehicleReleaseControl = form.get('vehicleReleasedDate');

            if (value === true) {
              vehicleReleaseControl?.reset();
              vehicleReleaseControl?.disable();
            } else {
              vehicleReleaseControl?.enable();
            }
          })
      }

      if(this.uiConfiguration.lienDetailsConfiguration?.showProvidedPartsOrAccessories &&
        this.uiConfiguration.lienDetailsConfiguration?.showVehicleReleasedDate) {
          const providedPartsOrAccessoriesControl = form.get('providedPartsOrAccessories');
          const partsProvidedDateControl = form.get('partsProvidedDate');
  
          if(providedPartsOrAccessoriesControl?.value === false) {
            partsProvidedDateControl?.disable();
          }
          
          providedPartsOrAccessoriesControl?.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
  
              if (value === false) {
                partsProvidedDateControl?.reset();
                partsProvidedDateControl?.disable();
              } else {
                partsProvidedDateControl?.enable();
              }
            })
        }

    return form;
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.courtOrderError$.next(errors?.filter(error => error.path.includes('/courtorder'))?.at(0)?.userFriendlyMessage);
    this.otherChangesError$.next(errors?.filter(error => error.path.includes('/otherchanges'))?.at(0)?.userFriendlyMessage);
    this.vehicleReleasedDateError$.next(errors?.filter(error => error.path.includes('/vehiclereleaseddate'))?.at(0)?.userFriendlyMessage);
    this.workLastCompletedDateError$.next(errors?.filter(error => error.path.includes('/worklastcompleteddate'))?.at(0)?.userFriendlyMessage);
    this.partsProvidedDateError$.next(errors?.filter(error => error.path.includes('/partsprovideddate'))?.at(0)?.userFriendlyMessage);
  }
}