import { Injectable } from '@angular/core';
import { Stepper } from '../../../interfaces/stepper';
import { Order, eOrderStatusType, eOrderType } from 'reg-hub-common';
import { instantiate } from './step-mapper';
import { BehaviorSubject } from 'rxjs';
import { NotFoundStepper } from './steps/not-found-stepper';
import { ValidationRule } from '../../../interfaces/validation-rule';


@Injectable({
  providedIn: 'root'
})
export class StepperService {

  private stepperSubject = new BehaviorSubject<Stepper | null>(null);
  public stepper$ = this.stepperSubject.asObservable();

  private validationRulesSubject = new BehaviorSubject<ValidationRule[] | null>(null);
  public validationRules$ = this.validationRulesSubject.asObservable();

  constructor() { }

  updateStepper(newStepper: Stepper) {
    this.stepperSubject.next(newStepper);
  }

  updateValidationRules(newValidationRules: ValidationRule[]) {
    this.validationRulesSubject.next(newValidationRules);
  }

  public initializeSteps(order: Order): Stepper {

    var governingStepperClass = this.buildClassReference(order);

    try {
      const stepper = instantiate(governingStepperClass);
      // this.updateStepper(stepper);
      return stepper;
    }
    catch (error) {
      return new NotFoundStepper();
    }
  }

  buildClassReference(order: Order): string {
    if ((order.jurisdiction ?? "") == "" || order.orderTypeID?.toString() == eOrderType.notSet.toString()) {
      return 'base-info-stepper';
    }

    var classReference = `${order.jurisdiction.toLowerCase()}-${order.orderTypeID.toLowerCase()}-stepper`

    if (order.jurisdiction === "QC") {
      const qcFormTypeID = order.referenceRegistrationInformation?.originalQCFormTypeID?.toString()?.toLowerCase() ?? order.lien?.qcFormTypeID?.toString().toLowerCase();
      classReference = `${order.jurisdiction.toLowerCase()}${this.getQcFormSection(order)}${order.orderTypeID.toLowerCase()}-stepper`
    }

    if (order.orderStatusTypeID.toLowerCase() == "historicaldraft") {
      classReference = `historical-${classReference}`;
    }

    console.log(classReference);

    return classReference;
  }

  private getQcFormSection(order: Order): string {
    let qcFormSection = '-'
    
    if(!order.lien) {
      return qcFormSection;
    }

    //if renewal or discharge use lien and only lien
    if ((order.orderTypeID === eOrderType.renewal ||
      order.orderTypeID === eOrderType.discharge) &&
      order.lien.qcFormTypeID) {
      qcFormSection = `-${order.lien.qcFormTypeID.toString().toLowerCase()}-`;
    } else {
      qcFormSection = `-${order.referenceRegistrationInformation?.originalQCFormTypeID?.toString()?.toLowerCase() ?? order.lien?.qcFormTypeID?.toString()?.toLowerCase() ?? qcFormSection}-`;
    }

    return qcFormSection;
  }
}
