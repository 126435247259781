import { Component } from '@angular/core';
import { Constants } from 'projects/reg-hub-client/src/constants';
import { Order, OrderManagerService, OrderUIConfiguration, Party, UIConfigurationService, Validation, ValidationProblem, ValidationService } from 'reg-hub-common';
import { FormControl } from '@angular/forms';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { Observable, of, takeUntil } from 'rxjs';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
export class ReviewComponent extends SteppedComponent {
  debtors!: Observable<Party[]>;
  dealers!:  Observable<Party[]>;
  securedParties!:  Observable<Party[]>;
  registeringAgents!:  Observable<Party[]>;

  termControl = new FormControl({ value: '0', disabled: true });
  shouldShowAdditionalInformation: boolean = false;

  constructor(orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    this.validationService.currentValidation$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(validation => this.validation$.next(validation));

    this.debtors = of(this.getDebtors());
    this.dealers = of(this.getDealers());
    this.securedParties = of(this.getSecuredParties());
    this.registeringAgents = of(this.getRegisteringAgents());

    this.termControl.setValue(this.order.lien!.isInfiniteTerm ? "Infinite" : (this.order.lien!.term?.toString() ?? ""))

    this.shouldShowAdditionalInformation = (order.jurisdiction == "AB"
      || order.jurisdiction == "MB"
      || order.jurisdiction == "QC"
      || order.jurisdiction == "NB"
      || order.jurisdiction == "NL"
      || order.jurisdiction == "NS"
      || order.jurisdiction == "NU"
      || order.jurisdiction == "NT"
      || order.jurisdiction == "PE"
      || order.jurisdiction == "YT");
  }

  override ngOnDestroy(): void {
    this.validationService.clearValidation();
    super.ngOnDestroy();
  }

  public override onSaving(): void {
    
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  getSecuredParties(): Party[] {
    const filteredSecuredParties = this.order.parties!.filter((party) => Constants.isSecuredParty(party.partyTypeID));
    return filteredSecuredParties;
  }

  getDebtors(): Party[] {
    const filteredDebtors = this.order.parties!.filter((party) => Constants.isDebtor(party.partyTypeID));
    return filteredDebtors;
  }

  getDealers(): Party[] {
    const filteredDealers = this.order.parties!.filter((party) => Constants.isDealer(party.partyTypeID));
    return filteredDealers;
  }

  getRegisteringAgents(): Party[] {
    const filteredRegisteringAgents = this.order.parties!.filter((party) => Constants.isRegisteringAgent(party.partyTypeID));
    return filteredRegisteringAgents;
  }

  isDischarge(): boolean {
    return (this.order.orderType.id == "Discharge" || this.order.orderType.id == "StandaloneDischarge");
  }

  isHistorical(): boolean {
    return this.order.orderStatusTypeID.includes('Historical');
  }
}
