<div class="padding">
    <div class="flex-container" *ngIf="!isLoadingOrder">
        <div class="flex-item" *ngIf="!isLoadingOrder">
            <div *ngIf="this.orderId" class="padding-sides-15 padding-bottom">
                <mat-card>
                    <mat-card-content>
                        <app-base-order-information-review [orderTypeGroupID]="orderTypeGroupID"></app-base-order-information-review>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="padding-sides-15">
                <mat-card>
                    <mat-card-content>
                        <div *ngIf="isHistorical" class="padding-sides-15 padding-bottom warnings padding-50" >
                            Historical Orders are not registered and should reflect the current state of the registration at the registry.
                        </div>
                        <app-order-stepper
                            [orderId]="orderId"
                            [orderTypeGroupID]="orderTypeGroupID"
                            (promptSubmissionConfirmationEvent)="submissionConfirmation()"
                            (promptDeletionConfirmationEvent)="deletionConfirmation()"
                            (promptCancelConfirmationEvent)="cancelConfirmation()">
                        </app-order-stepper>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="flex-item-fixed-size padding-top padding-right-15 order-fees-sticky">
            <app-order-fees></app-order-fees>
            <br />
            <mat-card *ngIf="order.orderStatusTypeID == 'Invalid'">
                <mat-card-content>
                    <div class="button-container">
                        <a mat-flat-button color="primary" class="flex-item-right-align"
                         matBadge="{{messageCount()}}" matBadgeColor="warn"
                            (click)="openOrderMessages()">Messages</a>
                    </div>
                </mat-card-content>
            </mat-card>
            <button
                *ngIf="order.orderStatusTypeID == 'Draft' || order.orderStatusTypeID == 'HistoricalDraft'"
                mat-raised-button
                (click)="deletionConfirmation()"
                color="warn"
                class="apply-padding flex-item form-field"
                aria-label="Delete draft button">
                Delete Draft
            </button>
        </div>
    </div>

    <!-- Spinner (replaces everything and waits until all requests have completed to show anything) -->
    <div *ngIf="this.isLoadingOrder" class="loading-spinner-overlay">
        <div class="loading-spinner-overlay">
            <mat-spinner></mat-spinner>
        </div>
    </div>
</div>
<!-- End of spinner -->