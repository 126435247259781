import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SelectOption, OrderManagerService, EnumeratedTypesRepositoryService, ValidationService, ValidationProblem, Order, Asset, eAssetType, eOrderType } from 'reg-hub-common';
import { eCriteriaVariation } from '../../services/stepper/steps/search-stepper';
import { EnvironmentUrlService } from '../../services/environment-url/environment-url.service';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';

@Component({
  selector: 'app-asset-search-criteria',
  templateUrl: './asset-search-criteria.component.html',
  styleUrls: ['./asset-search-criteria.component.css']
})
export class AssetSearchCriteriaComponent extends SteppedComponent {
  @Input() isFormEnabled: boolean = true;
  @Input() criteriaVariations: eCriteriaVariation[] = []

  protected assetTypes: SelectOption[] = [];
  protected shouldDisplaySearchFromDate: boolean = false;
  protected shouldDisplayAssetType: boolean = false;
  protected shouldDisplayExactSimilar: boolean = false;
  protected searchCriteriaDetails!: FormGroup;

  private orderForValidation: Order | null = null;

  protected serialNumberError$ = new BehaviorSubject<string | null | undefined>(null);
  protected searchFromDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    private formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    private enumeratedTypesRepo: EnumeratedTypesRepositoryService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  protected override init(order: Order): void {
    super.init(order);

    this.shouldDisplaySearchFromDate = this.criteriaVariations.includes(eCriteriaVariation.SearchFromDate);
    this.shouldDisplayAssetType = this.criteriaVariations.includes(eCriteriaVariation.AssetType);
    this.shouldDisplayExactSimilar = this.criteriaVariations.includes(eCriteriaVariation.ExactSimilar);

    const asset = this.order.assets?.at(0);
    this.searchCriteriaDetails = this.formBuilder.group({
      serialNumber: [asset?.serialNumber],
    })

    if (this.shouldDisplaySearchFromDate) {
      this.searchCriteriaDetails.addControl('searchFromDate', new FormControl(this.order.searchParameters?.searchFromDate));
    }

    if(this.shouldDisplayExactSimilar) {
      this.searchCriteriaDetails.addControl('exactSimilar', new FormControl(this.order.searchParameters?.exactOnlyResults));
    }

    let initialAssetType: eAssetType | undefined;
    if (this.shouldDisplayAssetType) {
      initialAssetType = asset?.assetTypeID;

      this.enumeratedTypesRepo.getAssetTypesAsSelectOptions(this.order.orderType.id as eOrderType, this.order.jurisdiction)
        .subscribe(jurisdictionTypes => {
          this.assetTypes = jurisdictionTypes;
        });
    } else {
      initialAssetType = eAssetType.None;
    }

    this.searchCriteriaDetails.addControl('assetTypeID', new FormControl(initialAssetType));

    if (!this.isFormEnabled) {
      this.searchCriteriaDetails.disable();
    }

    this.observableErrors$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(errors => this.pushErrors(errors));
    this.searchCriteriaDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.validate(this.getOrderForValidation()));
  }

  public override onSaving(): void {
    super.onSaving();

    const asset = this.order.assets?.at(0);

    if(!asset) {
      return;
    }

    this.updateAsset(asset);
    this.order.asset = asset;
    this.updateSearchParameters(this.order);
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    if(!errors) {
      return;
    }

    this.serialNumberError$.next(errors.filter(error => error.path.includes('/serialnumber'))?.at(0)?.userFriendlyMessage);
    this.searchFromDateError$.next(errors.filter(error => error.path.includes('searchfromdate'))?.at(0)?.userFriendlyMessage);

  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    return (errors?.length ?? 0) === 0 ? StepValidationState.ValidationSuccess : StepValidationState.ValidationError;
  }

  private getOrderForValidation(): Order {
    if(!this.orderForValidation) {
      this.orderForValidation = this.copyOrder(this.order);
    }

    const asset = this.order.assets?.at(0);

    if(!asset) {
      return this.orderForValidation!;
    }

    this.updateAsset(asset);
    this.orderForValidation.assets = [ asset ];
    this.updateSearchParameters(this.orderForValidation!);

    return this.orderForValidation!;
  }

  private updateAsset(asset: Asset) {
    asset.serialNumber = this.searchCriteriaDetails.get('serialNumber')!.value;
    asset.assetTypeID = this.searchCriteriaDetails.get('assetTypeID')!.value;
  }

  private updateSearchParameters(order: Order) { 
    if (this.shouldDisplaySearchFromDate) {
      order.searchParameters!.searchFromDate = this.searchCriteriaDetails.get('searchFromDate')!.value;
    }

    if(this.shouldDisplayExactSimilar) {
      order.searchParameters!.exactOnlyResults = this.searchCriteriaDetails.get('exactSimilar')!.value;
    }
  }
}
