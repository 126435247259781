import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { StepValidationState } from 'projects/reg-hub-client/src/interfaces/step';
import { SteppedComponent } from 'projects/reg-hub-client/src/interfaces/stepped-component';
import { Lien, Order, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TermComponent } from '../term/term.component';

@Component({
  selector: 'app-base-historical-lien-details',
  template: ''
})
export abstract class BaseHistoricalLienDetailsComponent extends SteppedComponent implements AfterViewInit {
  @ViewChild(TermComponent) termComponent!: TermComponent;

  termError$ = new BehaviorSubject<string | null | undefined>(null);
  expiryDateError$ = new BehaviorSubject<string | null | undefined>(null);
  amountError$ = new BehaviorSubject<string | null | undefined>(null);
  trustIndentureError$ = new BehaviorSubject<string | null | undefined>(null);
  additionalInformationError$ = new BehaviorSubject<string | null | undefined>(null);
  registrationNumberError$ = new BehaviorSubject<string | null | undefined>(null);
  registrationDateError$ = new BehaviorSubject<string | null | undefined>(null);

  @Input() isDisabled: boolean = false;
  protected lienDetails!: FormGroup;
  protected selectedOption: string = "term";
  protected term: number | null = null;
  protected isHistorical: boolean = false;

  /** Set lienDetails form group control */
  protected abstract buildForm(): FormGroup;

  constructor(
    protected formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(orderManager, validationService);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.isHistorical = (this.order.orderStatusTypeID.toLowerCase() == "historicaldraft");

    this.lienDetails = this.buildForm();

    this.lienDetails.addControl('registrationNumber', this.formBuilder.control(this.order.lien?.registrationNumber));
    this.lienDetails.addControl('registrationDate', this.formBuilder.control(this.order.lien?.registrationDate));
    this.lienDetails.addControl('expiryDate', this.formBuilder.control(this.order.lien?.expiryDate));

    this.lienDetails.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        try {
          let orderToValidate = this.getOrderToValidate();
          this.validate(orderToValidate);
        } catch {

        }
      });

    if (this.isDisabled) {
      this.lienDetails.disable();
    }
    
    const amountControl = this.lienDetails.get('amount');
    if(amountControl && !this.uiConfiguration.lienDetailsConfiguration?.isAmountEditable) {
      amountControl.disable();
    }
  }

  ngAfterViewInit(): void {
    if (this.termComponent) {
      if (this.uiConfiguration.lienDetailsConfiguration?.showInfinityTerm) {
        this.lienDetails.setControl('isInfiniteTerm', this.termComponent.termDetails.get('isInfiniteTerm'));
      }

      if (this.uiConfiguration.lienDetailsConfiguration?.showTerm) {
        this.lienDetails.setControl('term', this.termComponent.termDetails.get('term'));
      }

      if (this.uiConfiguration.lienDetailsConfiguration?.showExpiryDate) {
        this.lienDetails.setControl('expiryDate', this.termComponent.termDetails.get('expiryDate'));
      }
    }
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    if (!errors) {
      return;
    }

    this.termError$.next(errors?.filter(error => error.path.includes('/term'))?.at(0)?.userFriendlyMessage);
    this.expiryDateError$.next(errors?.filter(error => error.path.includes('/expirydate'))?.at(0)?.userFriendlyMessage);
    this.amountError$.next(errors?.filter(error => error.path.includes('/amount'))?.at(0)?.userFriendlyMessage);
    this.trustIndentureError$.next(errors?.filter(error => error.path.includes('/trustindenture'))?.at(0)?.userFriendlyMessage);
    this.additionalInformationError$.next(errors?.filter(error => error.path.includes('/additionalinformation'))?.at(0)?.userFriendlyMessage);
    this.registrationNumberError$.next(errors?.filter(error => error.path.includes('/registrationnumber'))?.at(0)?.userFriendlyMessage);
    this.registrationDateError$.next(errors?.filter(error => error.path.includes('/registrationdate'))?.at(0)?.userFriendlyMessage);
  }

  public override getValidationState(errors: ValidationProblem[] | undefined, order: Order): StepValidationState {
    const filteredErrors = errors?.filter(error =>
      error.path.includes('lien') &&
      !error.path.includes('collateral') &&
      !error.path.includes('/sumofhypothec'));
    return filteredErrors?.length ?? 0 > 0 ? StepValidationState.ValidationError : StepValidationState.ValidationSuccess;
  }

  protected getOrderToValidate(): Order {
    let orderToValidate = this.copyOrder(this.order);
    this.mapFormToLien(orderToValidate.lien);

    return orderToValidate;
  }

  protected mapFormToLien(lien: Lien | null | undefined): void {
    if (!lien) {
      return;
    }

    for (const key in this.lienDetails.controls) {
      if (key in lien) {
        (lien as any)[key] = this.lienDetails.controls[key].value;
      }
    }
  }

  onTermSelectionChange(event: MatRadioChange): void {
    if (event.value === true) {
      this.order.lien!.expiryDate = null;
      this.order.lien!.term = null;
      this.order.lien!.isInfiniteTerm = true;
      this.lienDetails?.get('isInfiniteTerm')?.setValue(true, { emitEvent: false });
    } else {
      this.order.lien!.isInfiniteTerm = false;
      this.lienDetails?.get('expiryDate')?.setValue(null, { emitEvent: false });
      this.lienDetails?.get('isInfiniteTerm')?.setValue(false, { emitEvent: false });
    }
  }

  public override onSaving(): void {
    this.mapFormToLien(this.order.lien);

    if (this.order.lien?.isInfiniteTerm === true) {
      this.order.lien.term = null;
    }
  }
}